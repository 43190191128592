import { kmsConstants } from "../_constants";
import { setLoading } from "./common.actions";
import api, { clearInstance } from "../_helpers/mainapi";
import qs from "qs";
import _ from "lodash";

export const getKmsPages =
    (
        text = "",
        status = 0,
        translation = "",
        activePage = 1,
        pageSize = 10,
        from = "",
        to = "",
        orderBy = "-lastm"
    ) =>
        async (dispatch, getState) => {
            dispatch(setLoading(true));
            api
                .get(`/values/getall`, {
                    params: {
                        SearchText: text,
                        Status: status,
                        Translation: translation,
                        Page: activePage,
                        PageSize: pageSize,
                        DateFrom: from,
                        DateTo: to,
                        OrderBy: orderBy,
                    },
                })
                .then((response) => {
                    dispatch(setLoading(false));
                    dispatch({
                        type: kmsConstants.GETPAGES_SUCCESS,
                        payload: response.data,
                    });
                });
        };

export const getKmsPagesProm = async (
    text = "",
    status = 3,
    translation = "",
    activePage = 1,
    pageSize = 10,
    from = "",
    to = "",
    isFromSearch = false,
    langSymbol = "EN"
) => {
    const response = await api.get(`/values/getlastpages`, {
        params: {
            SearchText: text,
            Status: status,
            Translation: translation,
            Page: activePage,
            PageSize: pageSize,
            DateFrom: from,
            DateTo: to,
            IncludeKeywords: true,
            IncludeDrugs: true,
            IsFromSearch: isFromSearch,
            LangSymbol: langSymbol
        },
    });
    return response;
};

export const setKmsId = (kmsid) => async (dispatch, getState) => {
    if (kmsid >= 0) {
        dispatch({ type: kmsConstants.GETKMSID_SUCCESS, kmsid });
    } else {
        dispatch({ type: kmsConstants.GETKMSID_FAILURE, kmsid });
    }
};
export const setLang = (lang) => async (dispatch, getState) => {
    if (!lang) {
        dispatch({ type: kmsConstants.GETLANG_FAILURE, lang });
    } else {
        dispatch({ type: kmsConstants.GETLANG_SUCCESS, lang });
    }
};
export const setTrId = (trid) => async (dispatch, getState) => {
    if (!trid) {
        dispatch({ type: kmsConstants.GETTRID_FAILURE, trid });
    } else {
        dispatch({ type: kmsConstants.GETTRID_SUCCESS, trid });
    }
};

export const setSearchValue = (searchValue) => async (dispatch, getState) => {
    dispatch({ type: kmsConstants.GETSEARCHVALUE_SUCCESS, searchValue });
};
export const setSearchPage = (searchPage) => async (dispatch, getState) => {
    dispatch({ type: kmsConstants.GETSEARCHPAGE_SUCCESS, searchPage });
};

export const getPage = (id, langSymbol = "EN") => async (dispatch, getState) => {
    const response = await api.get(`/values/getpage`, { params: { kmsid: id, langSymbol: langSymbol } });
    return response.data;
};

export const getPageByTitle =
    (kmstitle, kmsid) => async (dispatch, getState) => {
        const response = await api.get(`/values/getpagebytitle`, {
            params: { kmstitle: kmstitle, kmsid: kmsid },
        });
        return response.data;
    };

export const getPageHistory = (id) => async (dispatch, getState) => {
    const response = await api.get(`/values/getpagehistory`, {
        params: { kmsid: id },
    });
    return response.data;
};

export const getPageAttachments = (id, lang) => async (dispatch, getState) => {
    const response = await api.get(`/values/getpageattachments`, {
        params: { kmsid: id, lang: lang },
    });
    return response.data;
};

export const downloadfile = (id) => async (dispatch, getState) => {
    //console.log("downloadfile: ", id);
    const response = await clearInstance.get(`/values/downloadfile`, { params: { id } });
    return response.data;
};

export const getTranslation = (kmsid, lang) => async (dispatch, getState) => {
    const response = await api.get(`/values/gettranslation`, {
        params: { kmsid, lang },
    });
    return response.data;
};

export const saveAttachment = async (form, dispatch, getState) => {
    const response = await clearInstance.post(`/values/saveattachment`, {
        params: { form },
    });
    return response.data;
};

export const deleteAttachment = async (id) => {
    var attachment = { Id: id };
    //console.log("attachment: ", attachment);
    const response = await api.post(`/values/deletefile`, attachment);
    return response.data;
};

export const saveTranslation = (translation) => async (dispatch, getState) => {
    //console.log("translation: ", translation);
    const response = await api.post(`/values/savetranslation`, translation);
    return response.data;
};

export const savePage = (kmspage, comments, keywords, keywordsDrug, linkedPages, translationPages, changeLastModifiedDate, updatedLastModified) =>
    async (dispatch, getState) => {
        const response = await api.post(`/values/saveKms`, {
            kmspage,
            comments,
            keywords,
            drugs: keywordsDrug,
            linkedPages,
            translationPages,
            changeLastModifiedDate,
            updatedLastModified
        });
        return response.data;
    };

export const getAllPagesBySearch =
    (search, linkedIds, onlyPublished = false) => async (dispatch, getState) => {
        let { kmsid } = getState();
        const response = await api.get("/values/getallpagesbysearch", {
            params: { search: search, linkedIds: linkedIds, onlyPublished: onlyPublished },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        });
        return _.map(
            _.filter(response.data, (item) => item.value !== kmsid),
            (i) => {
                return { label: i.label, value: i.value };
            }
        );
    };

export const deleteKP = (id) => async (dispatch, getState) => {
    const response = await api.post(`/values/deleteknowledgepage`, { id: id });
    return response;
};
